
import { useTranslation } from 'react-i18next'
import { offerta } from './Data';
import { Button, Form, Input, Modal } from 'antd';

import { useStore } from '../store/Store';
import { api } from '../host/Host';
import axios from 'axios';
import { dangerAlert, successAlert } from './Alert';
import { useState } from 'react';

export default function FooterMobile() {
  const {t}=useTranslation()
    const setloader=useStore(state=>state.setloader)
    const [show, setshow]=useState(false)
    const [form] = Form.useForm();
    const onFinish=(val)=>{
      setloader(true)
          axios.post(`${api}/contacts/create/`, val).then(res=>{
            successAlert(t("data_sended"))
            setloader(false)
           closeModal()
          }).catch(err=>{
            setloader(false)
            dangerAlert(t('server_error_text'))
          })
    }
    const closeModal=()=>{
      setshow(false)
      form.setFieldsValue({
        name:'',
        text:'',
        contact:'',
      })

    }
  return (
    <>
    <div className='footer_mobile'>
       <p>{t("footer_text_mobile")} <a href={offerta[t('error_api')]} target='_blank'>{t("footer_link_mobile")}</a></p>
       <button onClick={()=>{setshow(true)}}>{t("footer_mobile_fikr")}</button>
    </div>
    <Modal open={show} onCancel={closeModal} onClose={closeModal} footer={false} title="" >
    <h1 className="title">{t("has_ques")}</h1>
            <p className="title_text">{t("support_text")}</p>
            <div className="support_form">
            <Form
            form={form}
    name="basic"
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
   initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    // onFinishFailed={onFinishFailed}
    
  >
     <Form.Item
      name="name"
      rules={[
        {
           required: true,
           message: t("not_empty"),
            
        }
      ]}
    >
      <Input  className='login_input' placeholder={t("firstname")} />
    </Form.Item>
    <Form.Item
      name="contact"
      rules={[
        {
           required: true,
           message: t("not_empty"),
            
        }
      ]}
    >
      <Input  className='login_input' placeholder={t("emailorphone")} />
    </Form.Item>
    <Form.Item
      name="text"
      rules={[
       {
           required: true,
           message: t("not_empty"),
            
        }
      ]}
    >
      <Input.TextArea rows={10} className='login_input' placeholder={t("support_ques")} />
    </Form.Item>
    
   
<Form.Item
      wrapperCol={{
        span: 24,
      }}
    >
      <Button className='btn_form' htmlType="submit">
        {t("send")}
      </Button>
    </Form.Item>
  </Form>
  </div>
    </Modal>
    </>
  )
}
