import React, { useEffect } from 'react'
import Menu from '../components/Menu'
import Header from '../components/Header'
import { useTranslation } from 'react-i18next'
import Profiles from './Profiles'
import { Link, Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import SelfProfile from './SelfProfile'
import {MdHome, MdQuestionMark} from 'react-icons/md'
import { useHref } from 'react-router-dom';
import Faq from './Faq'
import FaqItem from './FaqItem'
import { useStore } from '../store/Store'
import { checkUsersData } from '../components/Checkers'
import Chat from './Chat'
import CabinetUser from './CabinetUser'
import Cookies from 'js-cookie';
import ChatBox from './ChatBox'
import Likes from './Likes'
import Blocks from './Blocks'
import Setting from './Setting'
import FooterMobile from '../components/FooterMobile'
import Plans from './Plans'
import Payments from './Payments'
import { getBalance } from '../host/requests/User'

export default function Home() {
  const {chat_id}=useParams()
   const {t} =useTranslation()
   const history = useHref();
   const location=useLocation()
   const navigate=useNavigate()
   const user=useStore(state=>state.user)
   const setbalance=useStore(state=>state.setbalance)
   const setopen_menu=useStore(state=>state.setopen_menu)
   const checkToken = (type) => {
    const token = Cookies.get(type);
    if (token) {
      return token;
    } else {
      return null;
    }
  };
   useEffect(()=>{
    var access_token_data=checkToken('access_token_bez')
    if(access_token_data==null){
      navigate('/login')
    }else{
      getBalanceData(access_token_data)
    }
    setopen_menu(false)
   }, [location])

   
const getBalanceData=async(access_token_data)=>{
  try{
    var res3=await getBalance(access_token_data)
      setbalance(res3.data.balance)
  }catch(err){
    console.log(err)
  }
}
  return (
    <div className='bigBox'>
       
        <div className='bigContent'>
          {!checkUsersData(user)?<SelfProfile/>:
          <Routes> 
            <Route path='' element={<Profiles/>} />
            <Route path='profile' element={<SelfProfile/>} />
            <Route path='likes' element={<Likes/>} />
            <Route path='blocks' element={<Blocks/>} />
            <Route path='setting' element={<Setting/>} />
            <Route path='/user/:id' element={<CabinetUser/>} />
            <Route path='faq' element={<Faq/>} />
            <Route path='payments' element={<Payments/>} />
            <Route path='chat/:chat_id' element={<ChatBox/>} />
            <Route path='plans/:chat_id/:user_id/:chat_name' element={<Plans />} />
            <Route path='faq/:id' element={<FaqItem/>} />
            </Routes>}
          <FooterMobile/>
         
         
        </div>
    </div>
  )
}
