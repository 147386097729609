import { useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

import { deleteUserBlock, getUserById, getUserForChat, setLike } from "../host/requests/User"
import { useStore } from "../store/Store"
import man_icon from '../new_images/man_icon.png'
import place_icon from '../new_images/place_icon.svg'
import woman_icon from '../new_images/woman_icon.png'
import { TbLock } from "react-icons/tb"
import { Carousel as Car } from 'react-bootstrap'
import { useTranslation } from "react-i18next"
import { FaHeart, FaLock, FaRegEnvelope, FaRegHeart } from "react-icons/fa6"
import { dangerAlert, successAlert } from "../components/Alert"
import { IoIosArrowBack, IoIosArrowForward, IoIosWarning, IoMdCloseCircle } from "react-icons/io"
import { IoChevronBack, IoCloseOutline } from "react-icons/io5"
import { Button, Modal } from "antd"
export default function CabinetUser(){
    const {id}=useParams()
    const [index, setIndex] = useState(0);
    const {t}=useTranslation()
    const [data, setData]=useState(null)
    const [like_bol, setlike_bol]=useState(false)
    const [number, setnumber]=useState(0)
    const setloader=useStore(state=>state.setloader)
    const setrandomlike=useStore(state=>state.setrandomlike)
    const setopen_chat=useStore(state=>state.setopen_chat)
    const setchatsfirst=useStore(state=>state.setchatsfirst)
    const user=useStore(state=>state.user)
    const navigate=useNavigate()
    const random_users=useStore(state=>state.random_users)
    const delete_dark_list=useStore(state=>state.delete_dark_list)
    const [activeIndex, setActiveIndex] = useState(0);
    const [images, setimages]=useState(null)
    const token_access=useStore(state=>state.token_access)
    const [modal, contextHolder] = Modal.useModal();
    useEffect(()=>{
         getUserData()
    }, [id])
    const likeUser=async(id, bol)=>{
        var key =random_users.findIndex(x=>x.id===id)
        console.log(bol)
        if(!bol){
           
                var res=await setLike({user:id}, token_access)
                console.log(res)
                setrandomlike(key)
                successAlert(t("like_pushed"))
                setlike_bol(true)
                console.log('salom')
                setnumber(number+1)
         
        }
    }
const getUserData=async()=>{
    setloader(true)
    try{
    var res=await getUserById({pk:id}, token_access)
    console.log(res)
    setData(res.data)
    setloader(false)
    }catch(err){
        setloader(false)
        navigate('/cabinet')
    }
}

const handleSelect = (selectedIndex) => {
    setActiveIndex(selectedIndex);
  };

  const goToSlide = (index) => {
    setActiveIndex(index);
  };

const returnlike=(a)=>{
    var g=a.filter(x=>x.reaction==='liked')
    return(g.length>0)
   }
const checkBlockedMe=(a)=>{
    var h=a.filter(x=>x.reaction==='blocked' && x.user===user.id)
    return(h.length!==0)
}
const goToChat=async(item)=>{
    
   
    setloader(true)
   var chat_name=[user.chat_id, item.chat_id].sort()[0]+"__"+[user.chat_id, item.chat_id].sort()[1]
  var res = await getUserForChat({user:item.id}, token_access)
  console.log(res.data)
   setchatsfirst(res.data)
   setopen_chat(res.data)
   navigate("/cabinet/chat/"+chat_name)

} 

const goForward=(id)=>{
    if(id+1<images.length){
        handleSelect(id+1)
    }else{
        handleSelect(0)
    }
    
}
const goBack=(id)=>{
    if(id>0){
        handleSelect(id-1)
    }else{
        handleSelect(images.length-1)
    }
    
}
const returnblock=(a, id_user)=>{
    var g=a.filter(x=>x.reaction==='blocked' && x.user==id_user)
    if(g.length==0){
        return(null)
    }else{
        return(g[0].id)
    }
   }
   const deleteBlock=async(id)=>{
    setloader(true)
        try{
          var res=await deleteUserBlock(id, token_access)
          successAlert(t("block_open"))
          delete_dark_list(id)
          setloader(false)
          var a=data
          var key=a.reactions.findIndex(x=>x.id===id)
          a.reactions.splice(key, 1)
          setData(a)
          }catch(error){
          dangerAlert(t("block_unopen"))
          setloader(false)
        }
  }
  const openModalConfirm=(id)=>{
    console.log(id)
    modal.confirm({
        title: t("alert"),
        icon: <span className='warning_icon'><IoIosWarning /></span>,
        content: t("info_block"),
        okText: t('Ha'),
        cancelText: t("Yo'q"),
        onOk:()=>{deleteBlock(id)},
       
      });
}
    return (  <div className="user_info_big_box">
        {contextHolder}
        {data!=null?<div className="user_info_box">
           
       <div className='profile_card'>
             {checkBlockedMe(data.reactions)?<div className='block_icon'><TbLock /></div>:<></>}
             <div onClick={()=>{setimages(data.images); goToSlide(data.main_image!=null && data.images!=null && data.images.length>0?data.images.findIndex(x=>x.media===data.main_image.media):0)}} className='profile_img' style={{backgroundImage:`url(${data.main_image!=null?data.main_image.media:data.gender=='male'?man_icon:woman_icon})`}}></div>
             
           <div className='profile_text'>
           <div className='profile_text_box'>
               <div className='profile_name'>{data.first_name}, {data.age}</div>
               <div className='profile_address'><p>{data.state!=null? data.state[`name_${t('error_api')}`]:''}</p> <img src={place_icon} /></div>
          </div>
               <div className='profile_btn_box'>
               <div className='btn_pfof_div'></div>
          {<button  onClick={()=>{goToChat(data)}} className={`btn_prof`}><FaRegEnvelope /></button>}
          {<button  onClick={()=>{likeUser(data.id, returnlike(data.reactions))}} className={`btn_prof`}>{returnlike(data.reactions) || like_bol?<FaHeart  className='btn_heart liked_heart' />:<FaRegHeart   className='btn_heart unliked_heart'/>}</button>}
          <div className='btn_pfof_div'></div>
          </div>
               
           </div>
         
         
           
             </div>
             <div className="user_info_text">
             {returnblock(data.reactions, data.id)!=null?<button onClick={()=>{openModalConfirm(returnblock(data.reactions, data.id))}} className='btn_form btn_black' >{t("open_block")}</button>:<></>}
            <p>{t("about_me")}</p>
            <h1>{data.bio}</h1>
            <p>{t("user_meeting")}</p>
            {data.goal!=null?<div className="user_meeting">
                <img src={data.goal.image} />
                <p>{data.goal[`seen_${t("error_api")}`]}</p>
            </div>:<></>}
            
            <p>{t("address_text")}</p>
            <h1>{data.country!==null?data.country[`name_${t("error_api")}`]:''} {data.state!==null?data.state[`name_${t("error_api")}`]:''}</h1>
            <p>{t("interest")}</p>
            <div className="interest_box">
                {data.hobbies!=null?data.hobbies.map((item, key)=>{
                     return(<div key={key} className="interest_item">{item[`name_${t("error_api")}`]}</div>)
                }):<></>}
            </div>
            <p>{t("find_love")}</p>
            <h1>{data.filters!==null?data.filters.age_from+" - "+data.filters.age_till+" "+t("age")+" "+(data.filters.gender!='rather not say'?t(data.filters.gender):''):<></>}</h1>
            {data.images!=null && data.images.length>=2?<>
            <p>{t("images")}</p>
            <div className="user_images">
            <div className="user_images_box">
                    {data.images.slice(0, parseInt(data.images.length/2)).map((item, key)=>{
                       return <div onClick={()=>{setimages(data.images); goToSlide(key)}}  key={key} className="user_image_item" style={{backgroundImage:`url(${item!=null?item.media:data.gender=='male'?man_icon:woman_icon})`}} ></div>
                    })}
                </div>
                {data.images.length%2==0? <div className="user_images_box">
                    {data.images.slice(parseInt(data.images.length/2)).map((item, key)=>{
                       return <div onClick={()=>{setimages(data.images); goToSlide(key+parseInt(data.images.length/2))}}  key={key} className="user_image_item" style={{backgroundImage:`url(${item!=null?item.media:data.gender=='male'?man_icon:woman_icon})`}} ></div>
                    })}
                </div>:
                <div className="user_images_box">
                    {data.images.slice(parseInt(data.images.length/2), data.images.length-1).map((item, key)=>{
                       return <div onClick={()=>{setimages(data.images); goToSlide(key+parseInt(data.images.length/2))}} key={key} className="user_image_item" style={{backgroundImage:`url(${item!=null?item.media:data.gender=='male'?man_icon:woman_icon})`}} ></div>
                    })}
                </div>}
               
            </div>
            {data.images.length%2==1?<div onClick={()=>{setimages(data.images); goToSlide(data.images.length-1)}} className="user_image_item last_image" style={{backgroundImage:`url(${data.images!=null?data.images[data.images.length-1].media:data.gender=='male'?man_icon:woman_icon})`}} ></div>:<></>}
            </>:<></>}
            
           
            <Link to="/cabinet" className='btn_form' >
        {t("back")}
      </Link>
          </div>
          
    </div>:<></>}
    
    {images!==null && images.length!==0?<div className='car_box'>
    <div  onClick={()=>setimages(null)} className='car_back_image'></div>
   
    <div className="car_img_carousel">
    <div className='car_img'>
        <div className="car_img_box">
        <div className='car_img_head'>
            <div className="car_head_text">
            <div onClick={()=>{goBack(activeIndex)}} className="car_arrow">
                <IoIosArrowBack />
                </div>
                <div className="car_head_number">
                    {activeIndex+1}/{images.length}
                </div>
                <div onClick={()=>{goForward(activeIndex)}} className="car_arrow">
                <IoIosArrowForward />
                </div>
            </div>
            <div onClick={()=>setimages(null)} className='close_car_btn'>
            <IoCloseOutline />
        </div></div>
 <Car  indicators={false} controls={false}  activeIndex={activeIndex} onSelect={handleSelect} pause="hover" interval={null}>
    {images.map((item, key)=>{
return(<Car.Item  key={key}>
   
            
    <div className="car_img_img">
    <img src={item.media}/>
    </div>
        
        <div className='car_img_status'>
        {item.text}
        </div>
     
    
   </Car.Item>)
    })}
    
 </Car>
 </div>
 </div>
 </div>
    </div>:<></>}
    </div>)
} 