import { Button, Col, Form, Input, InputNumber, Modal, Row, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../store/Store';
import { getCountriesHttp, getHobbiesHttp, getStatesHttp } from '../host/requests/Countries';
import { addImageToUser, deleteUserImage, editImageToUser, editUserFilter, editUserProfile, getUserProfile, setUserImageMain } from '../host/requests/User';
import { dangerAlert, successAlert } from '../components/Alert';
import {  changeDateFormat, checkUsersData, maxDate, minDate } from '../components/Checkers';
import Compressor from 'compressorjs';
import { GoPencil, GoTrash } from "react-icons/go";
import { AiOutlinePlus } from 'react-icons/ai';
import { IoCloseOutline, IoCloudDownload } from 'react-icons/io5';
import { Carousel as Car } from 'react-bootstrap'
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { FaEye } from 'react-icons/fa6';
import axios from 'axios';
import { apiEn } from '../host/Host';
import { useNavigate } from 'react-router-dom';

export default function SelfProfile() {
    const [form] = Form.useForm();
    const [goal, setgoal]=useState(null)
    const {t}=useTranslation()
    const navigate=useNavigate()
    const [hobby, sethobby] = useState([]);
    const [hobby_filter, sethobby_filter] = useState([]);
    const [country_filter, setcountry_filter] = useState([]);
    const [birthdate, setbithdate] = useState("");
    const [gender, setgender] = useState('male');
    const [gender_filter, setgender_filter] = useState('female');
    const [country, setcountry] = useState(null);
    const [edit, setedit] = useState(null);
    const [region, setregion] = useState(null);
    const [open_modal, setopen_modal] = useState(false);
    const [desc, setdesc]=useState('')
    const [image, setimage]=useState(null)
    const [formattedminDate, setformattedminDate] = useState('');
    const [formattedmaxDate, setformattedmaxDate] = useState('');
    const [activeIndex, setActiveIndex] = useState(0);
    const [images, setimages]=useState(null)
    const [number, setnumber] = useState(0);
    const [minage, setminage] = useState(16);
    const [maxage, setmaxage] = useState(99);
    const token_access=useStore(state=>state.token_access)
    const countries=useStore(state=>state.countries)
    const setcountries=useStore(state=>state.setcountries)
    const hobbies=useStore(state=>state.hobbies)
    const goals=useStore(state=>state.goals)
    const user=useStore(state=>state.user)
    const filter=useStore(state=>state.filter)
    const sethobbies=useStore(state=>state.sethobbies)
    const setgoals=useStore(state=>state.setgoals)
    const setuser=useStore(state=>state.setuser)
    const setfilter=useStore(state=>state.setfilter)
    const states=useStore(state=>state.states)
    const setstates=useStore(state=>state.setstates)
    const setloader=useStore(state=>state.setloader)
    const filterOption = (input, option) =>(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
    

    const sendImagePost=async()=>{
      if(edit==null){
        if(image!=null){
          setloader(true)
          var data=new FormData()
           try{
            data.append("media", image)
            data.append("text", desc)
            var res=await addImageToUser(data, token_access)
            try{
              var res1=await getUserProfile(null, token_access)
              var g=user
              g.images=res1.data.images
              g.main_image=res1.data.main_image
              setuser(g)
              setloader(false)
              }catch (error){
              dangerAlert(t("server_error"))
              setloader(false)
             }
            closeModal()
          }catch(error){
            dangerAlert(t("server_error"))
            setloader(false)
          }
        }
      }else{
        setloader(true)
        var data=new FormData()
         try{
          if(image!=null){
          data.append("media", image)
          }
          data.append("text", desc)
          var res=await editImageToUser(edit, data, token_access)
          try{
            var res1=await getUserProfile(null, token_access)
            var g=user
            g.images=res1.data.images
            g.main_image=res1.data.main_image
            setuser(g)
            setloader(false)
            }catch (error){
            dangerAlert(t("server_error"))
            setloader(false)
           }
          closeModal()
        }catch(error){
          dangerAlert(t("server_error"))
          setloader(false)
        }
      }
      
     
    }

    const uploadImage=async(e)=>{
      
      if(e.target.files.length>0){
        const file=e.target.files[0]
      
        new Compressor(file, {
          quality: 0.8, // 0.6 can also be used, but its not recommended to go below.
          success: async (compressedResult) => {
          setimage(compressedResult)
          },
        });
      }else{
        setimage(null)
      }
     }

    const deleteImage=async(id)=>{
      try{
        var res=await deleteUserImage(id, token_access)
        try{
          var res1=await getUserProfile(null, token_access)
          var g=user
          g.images=res1.data.images
          g.main_image=res1.data.main_image
          setuser(g)
          setloader(false)
          }catch (error){
          dangerAlert(t("server_error"))
          setloader(false)
         }
      }catch(error){
        dangerAlert(t("server_error"))
      }
      
    }

    const goForward=(id)=>{
      if(id+1<images.length){
          handleSelect(id+1)
      }else{
          handleSelect(0)
      }
      
  }
  const goBack=(id)=>{
      if(id>0){
          handleSelect(id-1)
      }else{
          handleSelect(images.length-1)
      }
      
  }

    const handleSelect = (selectedIndex) => {
      setActiveIndex(selectedIndex);
    };
    const setMainImage=async(id)=>{
      try{
        var res=await setUserImageMain({image:id}, token_access)
        successAlert(t("main_image_change"))
        try{
          var res1=await getUserProfile(null, token_access)
          var g=user
          g.images=res1.data.images
          g.main_image=res1.data.main_image
          setuser(g)
          setloader(false)
          }catch (error){
          dangerAlert(t("server_error"))
          setloader(false)
         }
      }catch(error){
        dangerAlert(t("server_error"))
      }
    }
    const getUserProfileFunc=async()=>{
      try{
        var res1=await getUserProfile(null, token_access)
        successAlert(t("success_text"))
        setuser(res1.data)
        setloader(false)
        }catch (error){
        dangerAlert(t("server_error"))
        setloader(false)
       }
    }

    useEffect(()=>{
      setformattedmaxDate(maxDate())
      setformattedminDate(minDate())
      if(countries===null){
        getCountries()
       }
       if(hobbies===null){
        getHobbies()
       }
       var a=null
       if(user!==null){
        a={...user}
        if(a.hobbies!==null){
             a.hobbies=a.hobbies.map(item=>(item.id))
             sethobby(a.hobbies)
        }
        if(a.goal!=null){
          setgoal(a.goal.id)
        }
        if(a.birthdate!==null){
          setbithdate(user.birthdate)
        }else{
          setbithdate("")
        }
        setgender(user.gender)
       
        
        if(a.country!==null){
          getState(a.country.id)
          a.country=a.country.id
          setcountry(a.country)
        }
        if(a.state!==null){
          a.state=a.state.id
          setregion(a.state)
        }
       }
       if(filter!==null){
        var b={}
        b.gender_filter=filter.gender
        setgender_filter(filter.gender)
        b.age_from=filter.age_from
        b.age_till=filter.age_till
        if(filter.hobbies!==null){
          b.hobbies_filter=filter.hobbies.map(item=>(item.id))
          sethobby_filter(b.hobbies_filter)
        }
        if(filter.countries!==null){
          b.countries_filter=filter.countries.map(item=>(item.id))
          setcountry_filter(b.countries_filter)
        }
        
        a={...a, ...b}
       }
       form.setFieldsValue(a)
       setloader(false)
    }, [user])

    const validatePhone = (rule, value, callback) => {
      const phoneRegex = /[+\d]/g;
      if (value && !phoneRegex.test(value)) {
        callback('Please enter a valid phone address');
      } else {
        callback();
      }
    };
    const getCountries=async()=>{
      
      try{
           var res=await getCountriesHttp(null, token_access)
           setcountries(res.data)
      }catch(error){
           console.log(error)
      }
      
    }
    const getHobbies=async()=>{
      
      try{
        var res=await getHobbiesHttp(null, token_access)
        
           sethobbies(res.data)
        axios.get(`${apiEn}/ru/api/goals/`, {
          headers:{
              "Authorization":"Bearer "+token_access
          }
      }).then(res1=>{
          setgoals(res1.data)
          console.log(res1)
        }).catch(err=>{
          console.log(err)
        })
          
      }catch(error){
           console.log(error)
      }
      
    }
    const getState=async(val)=>{
      setregion(null)
      setloader(true)
      form.setFieldsValue({
        state: null
      });

      try{
           var res=await getStatesHttp({country:val}, token_access)
           
           setstates(res.data)
           setloader(false)
      }catch(error){
           console.log(error)
           setloader(false)
      }
      
    }

    const closeModal=()=>{
      setdesc("")
      setimage(null)
      setedit(null)
      setopen_modal(false)
    }
    const goToSlide = (index) => {
      setActiveIndex(index);
    };
    const onFinish=async(val)=>{
      
      var filter_data={}
      val.birthdate=changeDateFormat(val.birthdate)
      filter_data.age_till=val.age_till
      filter_data.age_from=val.age_from
      filter_data.countries=country_filter
      filter_data.hobbies=hobby_filter
      filter_data.gender=gender_filter
      val.hobbies=hobby
      val.goal=goal
      val.gender=gender
      if(hobby.length!=0){
        setloader(true)
        try{
          var res=await editUserProfile(val, token_access)
          setuser(res.data)
          var res1=await editUserFilter(filter_data, token_access)
          console.log(res1)
          setfilter(res1.data)
          
           if(!checkUsersData(res.data)){
            dangerAlert(t("fill_user_data_full"))
           }else{
            successAlert(t("success_text"))
            navigate('/cabinet')
           }
          
           setloader(false)
        }catch (error){
          console.log(error)
          if(error.response.data.detail){
            dangerAlert(t(error.response.data.detail))
          }else{
          dangerAlert(t("server_error"))}
          setloader(false)
        }
      }else{
        dangerAlert(t("no_hobby"))
      }
     
    }
    const deleteHobby=(val)=>{
      var a=hobby
      a.splice(a.indexOf(val), 1)
      sethobby(a)
      setnumber(number+1)
    }
    const addHobby=(val)=>{
      var a=hobby
     a.unshift(val)
      sethobby(a)
      setnumber(number+1)
    }
    const openAddImageModal=()=>{
      setopen_modal(true)
    }
    const deleteHobbyFilter=(val)=>{
      var a=hobby_filter
      a.splice(a.indexOf(val), 1)
      sethobby_filter(a)
      setnumber(number+1)
    }
    const addHobbyFilter=(val)=>{
      var a=hobby_filter
     a.unshift(val)
      sethobby_filter(a)
      setnumber(number+1)
    }
    const deleteCountryFilter=(val)=>{
      var a=country_filter
      a.splice(a.indexOf(val), 1)
      setcountry_filter(a)
      setnumber(number+1)
    }
    const addCountryFilter=(val)=>{
      var a=country_filter
     a.unshift(val)
      setcountry_filter(a)
      setnumber(number+1)
    }
  return (
    <div className='selfprofile'>
      <h1 className='title'>{t("say_about_self")}</h1>
      <p className='title_text'>{t("help_others")}</p>

      <div className='gender_big_box'>
      <label>{t("you")}</label>
      <div className='gender_box'>
      <div onClick={()=>{setgender('male')}} className={`gender_item ${gender==='male'?"active_gender":''}`}>
          <div className='gender_text'>{t("man")}</div>
          <div className={`gender_check`}></div>
        </div>
        <div onClick={()=>{setgender('female')}} className={`gender_item ${gender==='female'?"active_gender":''}`}>
          <div className='gender_text'>{t("woman")}</div>
          <div className={`gender_check`}></div>
        </div>
      </div>
      </div>
      
          <Form
    name="form"
    form={form}
   
    labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      onFinish={onFinish}
      onFinishFailed={()=>{dangerAlert(t("check_data_error"))}}
      initialValues={{ remember: false }}
      autoComplete='new-password'
  >
        <div className='self_top'>
            
          <Row className='self_row'>
        
          <Col className='self_col_left' lg={12} md={24} sm={24}>
          <Form.Item
    hasFeedback
      label={t("firstname")}
      name="first_name"
      rules={[
        {
          min:2,
          required: true,
          message: t("not_empty")
        },
      ]}
    >
      <Input className='inputFullName'/>
    </Form.Item>
          <Form.Item
          hasFeedback
      label={t("lastname")}
      name="last_name"
      rules={[
        {
          min:2,
          required: true,
          message: t("not_empty")
        },
      ]}
    >
      <Input className='inputFullName'/>
    </Form.Item>
    
    
     <Form.Item
    hasFeedback
      label={t("birthdate")}
     name="birthdate"
      
      rules={[
      
        {
          required: true,
          message: t("not_empty"),
         
        },
        
      ]}
    >
   
      <input type='date' className='birthdateinput' format="DD.MM.YYYY" min={formattedminDate} max={formattedmaxDate} />
    </Form.Item>

          </Col>  
          <Col  className='self_col_right' lg={12} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label={t("country")}
      name="country"
      rules={[
        {
          required: true,
          message: t("not_empty")
        },
      ]}
    >
    <select  value={country}  onChange={(e)=>{getState(e.target.value); setcountry(e.target.value);}}>
    {country===null? <option value={null}>{t("Tanlang")}</option>:<></>}
       {countries!==null?countries.map((item, key)=>( <option key={key} value={item.id}>{item[`name_${t("error_api")}`]}</option>)):<></>}
    </select>
   
    </Form.Item>
    <Form.Item
    hasFeedback
      label={t("region")}
      name="state"
      
      rules={[
        {
          required: true,
          message: t("not_empty")
        },
      ]}
    >
       <select value={region} onChange={(e)=>{setregion(e.target.value)}}>
       {region===null? <option value={null}>{t("Tanlang")}</option>:<></>}
       {states!==null?states.map((item, key)=>( <option key={key} value={item.id}>{item[`name_${t("error_api")}`]}</option>)):<></>}
    </select>
   
    </Form.Item>
    <Form.Item
  hasFeedback
  label={t("phone")}
  name="phone"
  
  rules={[
    {
      pattern: /^\+?[\d\s()-]+$/,
      message: t("phone_error_format"),
    },
    {
      required: true,
      message: t("not_empty"),
    },
  ]}
>
  <Input />
</Form.Item>

          </Col>
          
          <Col className='top_m_col col_self' lg={24} md={24} sm={24}>
          <h1 className='title'>{t("goals_title")}</h1>
          <p className='title_text'>{t("goals_text")}</p>
          {goals!=null?
          goals.map((item,key)=>{
            return ( <div onClick={()=>{setgoal(item.id)}} className={`goals_item ${item.id==goal?"active_goal":""}`} key={key}>
              <div className='goals_text_box'>
                <div className='goals_img'>
                  <img src={item.image} />
                </div>
                <div className='goals_text'>
                     <h1>{item[`name_${t("error_api")}`]}</h1>
                     <p dangerouslySetInnerHTML={{__html:item[`text_${t("error_api")}`]}} />
                </div>
              </div>
              <div className='gender_check'></div>
            </div>)
          })
          :<></>}
         
          </Col>

          <Col className='top_m_col' lg={24} md={24} sm={24}>
          <h1 className='title'>{t("about_self_title")}</h1>
          <p className='title_text'>{t("about_self_text")}</p>
          
          </Col>
          {hobbies!==null?
          <Col  className='col_self' lg={24} md={24} sm={24}>
          <label className='title_self_profile'>{t("choose_hobby")}</label>
            <div  className='mul_select_box'>
              <>
              {hobbies.filter(x=>hobby.includes(x.id)).map((item, key)=>(
                <div onClick={()=>{deleteHobby(item.id)}}  key={key} className='mul_select active_sel'>
                     {item[`name_${t("error_api")}`]}
                   </div>
                  ))}
              {hobbies.filter(x=>!hobby.includes(x.id)).map((item, key)=>(<div  key={key} onClick={()=>{if(hobby.length<3){addHobby(item.id)}}} className={`mul_select ${hobby.length>=3?"dis_sel_item":''}`}>
                {item[`name_${t("error_api")}`]}
                   </div>))}
             
             </>
              
            </div>
          </Col>:<></>}

          <Col className='col_self' lg={24} md={24} sm={24}>
          
          <Form.Item
          hasFeedback
          label={t("comment")}
          name="bio"
         
    >
<Input.TextArea autoSize={{ minRows: 10, maxRows: 10 }} />
    </Form.Item>
          </Col>
         
         
         
          <Col className='col_self top_m_col' lg={24} md={24} sm={24}>
          <h1 className='title'>{t("add_image")}</h1>
          <p className='title_text'>{t("add_image_text")}</p>
          <div className='avatars_box'>
            {user!=null && user.images!==null?user.images.map((item, key)=>{
              return(
                <div className='avatars_col' key={key}>
              <div className='avatars_item'>
              <div className='avatars_img' style={{backgroundImage:`url(${item.media})`}}></div>
              <div className='avatars_btn'>
              <button onClick={()=>{deleteImage(item.id)}} className='avatar_delete' ><GoTrash /></button>
              <button onClick={()=>{openAddImageModal(); setedit(item.id); setdesc(item.text!=null?item.text:'');}} className='avatar_delete' ><GoPencil /></button>
              <button onClick={()=>{setimages(user.images); goToSlide(key)}} className='avatar_delete' ><FaEye /></button>
             
              </div>
              <div className='image_switch'>
              <Switch disabled={user.main_image!=null && item.id===user.main_image.id?true:false} 
              onChange={()=>{setMainImage(item.id)}}
              checked={user.main_image!=null && item.id===user.main_image.id?true:false} />
              </div>
              
              </div></div>)
            }):<></>}
            {
              user!=null && (user.images===null || user.images.length<8)?
              
              <div className='avatars_col'><div className='avatars_item'>
                <div onClick={openAddImageModal} className='add_avatar avatars_img'>
                  
                
                   <span><AiOutlinePlus /></span>
                   <p>{t("add_image")}</p>
                  
                </div></div>
              </div>:<></>
            }
        </div>
          </Col>
          <Col className='top_m_col' lg={24} md={24} sm={24}>
          <h1 className='title'>{t("who_you_need")}</h1>
          <br/>
          <div className='gender_big_box'>
      <div className='gender_box'>
      <div onClick={()=>{setgender_filter('male')}} className={`gender_item ${gender_filter=='male'?"active_gender":''}`}>
          <div className='gender_text'>{t("man")}</div>
          <div className={`gender_check`}></div>
        </div>
        <div onClick={()=>{setgender_filter('female')}} className={`gender_item ${gender_filter=='female'?"active_gender":''}`}>
          <div className='gender_text'>{t("woman")}</div>
          <div className={`gender_check`}></div>
        </div>
      </div>
      </div>
          </Col>
          <Col className='self_col_left' lg={12} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label={t("age_from")}
      name="age_from"
      rules={[
        {
          type: 'number',
          min: 16,
          max: maxage,
          message: t("age_from_between_error")
        },
        {
          required: true,
          message: t("not_empty")
        }
      ]}
    >
      <InputNumber onChange={(val)=>{setminage(val)}}/>
    </Form.Item>
        
  
    
          </Col>
          <Col className='self_col_right' lg={12} md={24} sm={24}>
          <Form.Item
          hasFeedback
      label={t("age_till")}
      name="age_till"
      rules={[
        {
          type: 'number',
          min: minage,
          max: 99,
          message: t("age_from_between_error")
        },
        {
          required: true,
          message: t("not_empty")
        }
      ]}
    >
      <InputNumber  onChange={(val)=>{setmaxage(val)}}/>
    </Form.Item>
          </Col>
          {countries!==null?
          <Col className='col_self' lg={24} md={24} sm={24}>
          <label className='title_self_profile'>{t("choose_country_other")}</label>
            <div  className='mul_select_box'>
              <>
              {countries.filter(x=>country_filter.includes(x.id)).map((item, key)=>(
                <div key={key} onClick={()=>{deleteCountryFilter(item.id)}} className='mul_select active_sel'>
                     {item[`name_${t("error_api")}`]}
                    
                  </div>
                  ))}
              {countries.filter(x=>!country_filter.includes(x.id)).map((item, key)=>(<div  key={key} onClick={()=>{if(country_filter.length<10){addCountryFilter(item.id)}}} className={`mul_select ${country_filter.length>=10?"dis_sel_item":''}`}>
                {item[`name_${t("error_api")}`]}
              
             </div>))}
             
             </>
              
            </div>
          </Col>:<></>}
          
          
          <div className='btnBox'>
        <Button className='btn_form' htmlType="submit">
          {t("save")}
        </Button>
        </div>
        
          </Row>
        </div>
        
        
        </Form>
        <Modal onCancel={closeModal} onOk={sendImagePost}  open={open_modal} title={t("add_image")}>
          <div className='add_image_form'>
          <label>{t("choose_image")}</label>
          <div className={`image_input_box ${image!=null?"active_image_file":''}`}>
          <input onChange={uploadImage} accept="image/png, image/jpeg, image/jpg" type='file'/>
          <div className='image_input_text'>
          <span><IoCloudDownload /></span>
          <p>{t("choose_file")}</p>
          </div>
          </div>
          

          <label>{t("write_status")} <span>({desc.length}/300)</span></label>
          <textarea rows={5} value={desc} onChange={(e)=>{setdesc(e.target.value.slice(0, 300))}}></textarea>
          </div>
          
        </Modal>
        {images!==null && images.length!==0?<div className='car_box'>
    <div  onClick={()=>setimages(null)} className='car_back_image'></div>
   
    <div className="car_img_carousel">
    <div className='car_img'>
        <div className="car_img_box">
        <div className='car_img_head'>
            <div className="car_head_text">
            <div onClick={()=>{goBack(activeIndex)}} className="car_arrow">
                <IoIosArrowBack />
                </div>
                <div className="car_head_number">
                    {activeIndex+1}/{images.length}
                </div>
                <div onClick={()=>{goForward(activeIndex)}} className="car_arrow">
                <IoIosArrowForward />
                </div>
            </div>
            <div onClick={()=>setimages(null)} className='close_car_btn'>
            <IoCloseOutline />
        </div></div><Car  indicators={false} controls={false} activeIndex={activeIndex} onSelect={handleSelect} pause="hover" interval={null}>
    {images.map((item, key)=>{
return(<Car.Item  key={key}>
   
            
    <div className="car_img_img">
    <img src={item.media}/>
    </div>
        
        <div className='car_img_status'>
        {item.text}
        </div>
     
    
   </Car.Item>)
    })}
    
 </Car> </div>
 </div>
 </div>
    </div>:<></>}
    </div>
  )
}
