import React, { useEffect, useState } from 'react'
import { useStore } from '../store/Store'
import { checkDate, formatDateList, noAvatar } from '../components/Checkers'
import { useTranslation } from 'react-i18next'
import { GoPlus } from "react-icons/go";
import man_icon from '../new_images/man_icon.png'
import woman_icon from '../new_images/woman_icon.png'
import { deleteUserBlock, getdark_listUsers, getLikeUsers, getUserById } from '../host/requests/User';
import { Link, useNavigate } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import place_icon from '../new_images/place_icon_black.svg'
import place_icon_white from '../new_images/place_icon.svg'
import { IoIosWarning } from 'react-icons/io';
import { dangerAlert, successAlert } from '../components/Alert';
import { Modal } from 'antd';
export default function Blocks() {
  const dark_lists=useStore(state=>state.dark_lists)
  const token_access=useStore(state=>state.token_access)
  const [modal, contextHolder] = Modal.useModal();
  const dark_list_next=useStore(state=>state.dark_list_next)
  const setdark_list_page=useStore(state=>state.setdark_list_page)
  const setdark_lists=useStore(state=>state.setdark_lists)
  const setdark_list_next=useStore(state=>state.setdark_list_next)
  const dark_list_page=useStore(state=>state.dark_list_page)
  const setloader=useStore(state=>state.setloader)
  const isBlue=useStore(state=>state.isBlue)
  const delete_dark_list=useStore(state=>state.delete_dark_list)
  const [dark_list_spin, setdark_list_spin]=useState(false)
  const navigate=useNavigate()
  const {t} =useTranslation()

  
  const openModalConfirm=(id)=>{
    console.log(id)
    modal.confirm({
        title: t("info"),
        icon: <span className='warning_icon'><IoIosWarning /></span>,
        content: t("info_block"),
        okText: t('Ha'),
        cancelText: t("Yo'q"),
        onOk:()=>{deleteBlock(id)},
       
      });
}
const getdark_lists=async()=>{
  if(dark_list_next!==null){
   
    try{
      var res=await getdark_listUsers({page:dark_list_page}, token_access)
    
      setdark_list_next(res.data.next)
      setdark_list_page(dark_list_page+1)
      setdark_lists(res.data.results)
     
   }catch(error){
      console.log(error)
     
   }
  }
}
const deleteBlock=async(id)=>{
  setloader(true)
      try{
        var res=await deleteUserBlock(id, token_access)
        successAlert(t("block_open"))
        delete_dark_list(id)
        setloader(false)
        }catch(error){
        dangerAlert(t("block_unopen"))
        setloader(false)
      }
}
  return (
    <>
    {contextHolder}
   
   <div className='like_big_box'>
   <h1 className='title'>{t("who_i_block")}</h1>
     {dark_lists.length===0? <div className='listBox'>
       <div className='emptyList'></div>
   </div>:<div className='like_box'>{dark_lists.map((item, key)=>{

     return(item.user!=null?<div className='like_item_col'><Link to={"/cabinet/user/"+item.user.id} key={key} className='like_item'>
      <span>{checkDate(item.created_at, t("error_api"))}</span>
     <div className='user_box'>
      <div className='avatar'>
      <div className='avatar_box' style={{backgroundImage:`url(${item.user!==null && item.user.main_image!==null?item.user.main_image.media:item.user.gender=='male'?man_icon:woman_icon})`}}></div>
        
      </div>
      <div className='username'>
           <h1>{item.user!==null?item.user.first_name:""}</h1>
           <div className='like_address'><p>{item.reason!==null?item.reason[`reason_${t("error_api")}`]:''}</p></div>
      </div>
    </div>
    
    </Link></div>:<></> )
   })}
           
     {dark_list_next!==null && dark_list_next!=="a"?
      <div onClick={()=>{getdark_lists()}} className='addBtnBox'>
      {dark_list_spin?<div className='loadBtn_box'><PuffLoader /></div>: <button><GoPlus/></button>}
     
    </div>
     :<></>}
     
       </div>}
       </div>
       </>
  )
}
