import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import './blue.css'
import Home from './pages/Home';
import { useStore } from './store/Store';
import { Route, Routes, useHref, useNavigate } from 'react-router-dom';
import LoginBox from './login_register/LoginBox';
import Loader from './components/Loader';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Cookies from 'js-cookie';
import { getBalance, getUserFilter, getUserProfile } from './host/requests/User';
import { checkUsersData } from './components/Checkers';
import Menu from './components/Menu';
export default function App() {
  const isBlue=useStore(state=>state.isBlue)
  const setbalance=useStore(state=>state.setbalance)
  const loader=useStore(state=>state.loader)
  const setloader=useStore(state=>state.setloader)
  const setuser=useStore(state=>state.setuser)
  const setfilter=useStore(state=>state.setfilter)
  const settoken_access=useStore(state=>state.settoken_access)
  const user=useStore(state=>state.user)
    const settoken_refresh=useStore(state=>state.settoken_refresh)
  const history = useHref();
  const navigate=useNavigate()
  const checkToken = (type) => {
    const token = Cookies.get(type);
    if (token) {
      return token;
    } else {
      return null;
    }
  };
  const getUserData=async(access_token_dat)=>{
    setloader(true)
    try{
      var res1=await getUserProfile(null, access_token_dat)
      var res2=await getUserFilter(null, access_token_dat)
      var res3=await getBalance(access_token_dat)
      setbalance(res3.data.balance)
      if(!checkUsersData(res1.data, res2.data)){
       console.log('salom')
      }
      setuser(res1.data)
      setfilter(res2.data)
      setloader(false)
      }catch (error){
        setloader(false)
      
     }
  }
  useEffect(()=>{
     var access_token_data=checkToken('access_token_bez')
     var refresh_token_data=checkToken('refresh_token_bez')
     if(access_token_data!=null){
           settoken_access(access_token_data)
           settoken_refresh(refresh_token_data)
           getUserData(access_token_data)
          }

    if(access_token_data===null && history!=="/login" && history!=="/" && history.indexOf("change-password")===-1  && history.indexOf("register")===-1 ){
      navigate("/login")
    }
    
     const handleResize = () => {
      
      document.body.style.width = '100%';
    };

    // Add event listener for resize
    window.addEventListener('resize', handleResize);
    const handleOrientationChange = () => {
      // Prevent the default behavior (rotation) when the orientation changes
      window.addEventListener('orientationchange', (event) => {
        event.preventDefault();
      });
    };

    handleOrientationChange();
    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
 }, [])
  return (
    <div className={isBlue?"blueBox":''} >
  <Navbar/>
  { user!=null?<div className='bigMenu'>
            <Menu/>
        </div>:<></>}
     {loader?<Loader/>:<></>}
      <Routes>
        <Route path="/*" element={<LoginBox/>} />
        <Route path="/cabinet/*" element={<Home/>} />
      
      </Routes>
      <Footer/>
      <div className='history' style={{display:'none'}}>{`${history}`}</div>
    </div>
    
  )
}
