
import dayjs from 'dayjs'
export const checkUsersData=(data)=>{
    return(data!==null && data.phone!==null && data.gender!==null && data.first_name!==null && data.last_name!==null && data.birthdate!==null && data.country!==null && data.state!==null)
}

export const checkDate=(a, lan)=>{
    if(a===null){
        return(old_now[lan])
    }else{
      
        var inputDate = new Date(a);
        var currentDate = new Date();
        var timeDifference = currentDate - inputDate;
        var minutAgo = timeDifference / (1000 * 60);
        minutAgo = Math.floor(minutAgo);
        if(minutAgo<=0){
            return(now[lan])
        }else if(minutAgo<=60){
            return(minutAgo+" "+minut_befor[lan])
        } else{
            var hoursAgo = timeDifference / (1000 * 60 * 60);
            hoursAgo = Math.floor(hoursAgo);
           if(hoursAgo<=24){
                return(hoursAgo+" "+hour_befor[lan])
            }else{
                var daysAgo = timeDifference / (1000 * 60 * 60 * 24);
                daysAgo = Math.floor(daysAgo);
                if(daysAgo<=6){
                    return(daysAgo+" "+day_befor[lan])
                }else{
                    var weeksAgo = timeDifference / (1000 * 60 * 60 * 24 * 7);
                    weeksAgo = Math.floor(weeksAgo);
                    if(weeksAgo<=4){
                        return(weeksAgo+" "+week_befor[lan])
                    }else{
                        var daysAgo = timeDifference / (1000 * 60 * 60 * 24);
                        var monthsAgo = daysAgo / 30.44;
                        monthsAgo = Math.floor(monthsAgo);
                        if(monthsAgo<=20){
                            return(monthsAgo+" "+month_befor[lan])
                        }else{
                            var yearsAgo = timeDifference / (1000 * 60 * 60 * 24 * 365.25);
                            yearsAgo = Math.floor(yearsAgo);
                            if(yearsAgo<=2){
                                return(yearsAgo+" "+year_befor[lan])
                            }else{
                                return(old_now[lan])
                            }
                               
                        }
                        
                    }
                }
            }
         
        } 
        
    }
   
  }
 
 export const old_now={
    "uz":"ancha oldin",
    "ru":"давным давно",
    "en":"a long time ago",
}
const now={
    "uz":"hozirgina",
    "ru":"прямо сейчас",
    "en":"just now",
}
 const minut_befor={
     "uz":"minut oldin",
     "ru":"минуту назад",
     "en":"a minute ago",
 }
 const week_befor={
     "uz":"hafta oldin",
     "ru":"недели назад",
     "en":"weeks ago",
 }
 const month_befor={
     "uz":"oy oldin",
     "ru":"месяца назад",
     "en":"months ago",
 }
 const year_befor={
     "uz":"yil oldin",
     "ru":"год назад",
     "en":"year ago",
 }
 const hour_befor={
     "uz":"soat oldin",
     "ru":"часа назад",
     "en":"hours ago",
 }
 const day_befor={
     "uz":"kun oldin",
     "ru":"дня назад",
     "en":"days ago",
 }
 


export function disabledDate(current) {
    const minDate = dayjs().subtract(90, 'year');
    const maxDate = dayjs().subtract(18.5, 'year'); 
  
    return current && (current < minDate || current > maxDate);
  }
  export function minDate() {
    let currentDate = new Date();
    let ninetyYearsAgo = new Date(currentDate);
    ninetyYearsAgo.setFullYear(currentDate.getFullYear() - 90);
    return formatDateToYYYYMMDD(ninetyYearsAgo)
  }
  export function maxDate() {
    let currentDate = new Date();
    let ninetyYearsAgo = new Date(currentDate);
    ninetyYearsAgo.setFullYear(currentDate.getFullYear() - 16);
    
    return formatDateToYYYYMMDD(ninetyYearsAgo)
  }
 export function formatDateToYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
     console.log( `${year}-${month}-${day}`)
    return `${year}-${month}-${day}`;
  }
 export function changeDateFormat(inputDate) {
    
    var parts = inputDate.split('-');
  
    // Rearrange the parts to form the new date format
    var newDateFormat = parts[2] + '-' + parts[1] + '-' + parts[0];
  
    return newDateFormat;
  }

  export const formatDateList=(date, lang)=>{
    if(date===null || date==='0001-01-01T00:00:00Z'){
        return(old_now[lang])
    }else{
        var parsedTimestamp = new Date(date);

        parsedTimestamp.setHours(parsedTimestamp.getHours() + 5);
        var newTimestamp = parsedTimestamp.toISOString();
    
        var year=newTimestamp.slice(0, 4)
        var month=newTimestamp.slice(5,7)
        var day=newTimestamp.slice(8,10)
        var time=newTimestamp.slice(11, 16)
        return(`${day}.${month}.${year} ${time}`)
    }
  }
