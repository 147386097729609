import React from 'react'
import { noAvatar } from '../components/Checkers'
import { useStore } from '../store/Store'
import { TbLock } from 'react-icons/tb'
import { PuffLoader } from 'react-spinners'

import man_icon from '../new_images/man_icon.png'
import woman_icon from '../new_images/woman_icon.png'
import { Col, Row } from 'antd'
import { useNavigate } from 'react-router-dom'
export default function ProfileSearch({data,  setdata, loader}) {
    const addusersrandom=useStore(state=>state.addusersrandom)
    const user=useStore(state=>state.user)
    const setsearch=useStore(state=>state.setsearch)
    const setopen_search=useStore(state=>state.setopen_search)
    const navigate=useNavigate()
    const getUserByClick=(a)=>{
       setopen_search(false)
       setdata([])
       setsearch("")
       navigate("/cabinet/user/"+a.id)
    }
    const checkBlockedMe=(a)=>{
        var h=a.filter(x=>x.reaction==='blocked' && x.user===user.id)
        return(h.length!==0)
    }
  return (
    <div className='search_box'>
        {data.length!==0?<Row className='search_row'>
            {data.map((item, key)=>{
                return(
                  <Col key={key}  lg={4} md={8} sm={24}>
                    <div className='search_col'>
                    <div onClick={()=>{getUserByClick(item)}} className='search_card'>
                    {checkBlockedMe(item.reactions)?<div className='block_icon'><TbLock /></div>:<></>}
                    <div className='avatar'>
                    <div className='avatar_img' style={{backgroundImage:`url(${item.main_image!==null?item.main_image.media:item.gender=='male'?man_icon:woman_icon})`}}></div>
                    </div>
                    <p>{item.first_name}</p>
                    </div>
                    </div>
                    </Col>
                   
                )
            })}
           
        </Row>:<div className='listBox empty_user'>
           {loader?<div className='loadBtnSearch'><PuffLoader size={150} color='#FF6865' /></div>: <div className='emptyList'></div>}
        </div>}
    </div>
  )
}
