import { Carousel } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import {IoSearch} from 'react-icons/io5'
import { useTranslation } from 'react-i18next'
import ProfileCards from './ProfileCards'
import {FaArrowRight, FaArrowLeft} from 'react-icons/fa6'
import {MdOutlineSubdirectoryArrowLeft, MdOutlineSpaceBar} from 'react-icons/md'
import ProfileSearch from './ProfileSearch'
import { getUsersDataRandomSearch } from '../host/requests/User'
import { useStore } from '../store/Store'
import Footer from '../components/Footer'
export default function Profiles() {
   const search=useStore(state=>state.search)
   const setsearch=useStore(state=>state.setsearch)
   const [loader, setloader]=useState(false)
   const [data, setdata]=useState([])
  const token_access=useStore(state=>state.token_access)

    const {t}=useTranslation()
   useEffect(()=>{
    if(search.length!==0){
        getDataSearch()
    }else{
      setdata([])
    }
   },[search])
   
   const getDataSearch=async()=>{
    try{
      setdata([])
      setloader(true)   
   var res=await getUsersDataRandomSearch({full_name:search}, token_access)
  setdata(res.data.results)
  console.log(res.data.results)
   setloader(false)
  }catch(error){
   setloader(false)
   console.log(error)
  }
   }
  return (
    <div className='profile'>
     
        <div>
          {search.length===0?<ProfileCards/>:<ProfileSearch setdata={setdata} loader={loader} data={data}/>}
        </div>
     
    </div>
  )
}
