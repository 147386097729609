import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { activateChat, createClick, getBalance, getPalans } from '../host/requests/User'
import { useStore } from '../store/Store'
import logo from '../new_images/logo.png'
import { Col, Modal, Row } from 'antd'
import { useTranslation } from 'react-i18next'

import click from '../new_images/click.png'
import { dangerAlert, formatPayment, successAlert } from '../components/Alert'
export default function Plans() {
    const {chat_id, user_id, chat_name}=useParams()
    const [plans, setplans]=useState([])
    const {user, balance}=useStore()
    const [show, setshow]=useState(false)
    const [summa, setsumma]=useState("")
    const {token_access, setloader, setbalance} = useStore()
    const {t} =useTranslation()
    const navigate=useNavigate()
    useEffect(()=>{
          getDataPlans()
          getUserData()
    }, [token_access])
    
      const getUserData=async()=>{
        setloader(true)
        try{
          var res3=await getBalance(token_access)
          setbalance(res3.data.balance)
          setloader(false)
          }catch (error){
            setloader(false)
          
         }
      }
    const getDataPlans=()=>{
        setloader(true)
        getPalans({}, token_access).then(res=>{
            setplans(res.data)
            setloader(false)
        }).catch(err=>{
            console.log(err)
        })
    }

    const activateChatData=(id)=>{
        setloader(true)
        activateChat({
            tarif:id,
            conversation:chat_id,
            user:user_id
        }, token_access).then((res)=>{
            successAlert(t("plan_activated"))
            setloader(false)
            navigate('/cabinet/chat/'+chat_name)
            
        }).catch(err=>{
            setloader(false)
        })
    }
    const handleOk=()=>{
     
        var sum_str=Number(summa.replaceAll(" ", ""))
           if(isNaN(sum_str) || sum_str<=0){
            dangerAlert(t('write_summa'))
           }else{
            setloader(true)
            createClick({"amount":String(sum_str)}, token_access).then(res=>{
              setloader(false)
              if(res!=null && res.data!=null){
                 window.location.href=`https://my.click.uz/services/pay?service_id=${res.data.service_id}&merchant_id=${res.data.merchant_id}&amount=${res.data.amount}&transaction_param=${res.data.transaction_param}&return_url=${window.location.href}`
              }else{
                dangerAlert(t("server_error_text"))
              }
                 
            }).catch(err=>{
              dangerAlert(t("server_error_text"))
            })
           }
      }
      const handleCancel=()=>{
           setshow(false)
      }
  
      const onchange=(e)=>{
        var val=e.target.value
        if(val.length!==0){
          val=Number(val.replace(/\D/g, '')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        }
        setsumma(val)
      }
  return (
    <div className='plans'>
         <div className='payments_header'>
        <p>{t("your_summ")}: <b>{formatPayment(balance)}</b> {t("so'm")}</p>
        <p>{t("add_money")}: <div onClick={()=>{setshow(true)}} className='click_btn' ><img src={click} /></div></p>
       </div>
       <div className='payments_header mobile_head'>
        <p><b>{formatPayment(balance)}</b> {t("so'm")}</p>
        <p><div onClick={()=>{setshow(true)}} className='click_btn' ><img src={click} /></div></p>
       </div>
        
        <Row>
          {plans.map((item, key)=>{
            return(item.duration!=null && item.summa!=null?<Col className='plan_col'  key={key} lg={8} md={12} sm={24}>
            <div className='plan_card'>
                <img src={logo}/>
                <h2>{item[`name_${t("error_api")}`]}</h2>
                <h1>{formatPayment(item.summa)} so'm</h1>
                <p>{parseInt(item.duration/86400)>0?parseInt(item.duration/86400)+" "+t("kun"):parseInt(item.duration/3600)>0?parseInt(item.duration/3600)+" "+t("soat"):parseInt(item.duration/60)>0?parseInt(item.duration/60)+" "+t("min"):item.duration+" "+t("sekund")}</p>
                {!isNaN(Number(balance)) && !isNaN(Number(item.summa)) && Number(balance)>=Number(item.summa)?
            <button  onClick={()=>{activateChatData(item.id)}} className='btn_form'>{t("activate_plan")}</button>:
            <button  onClick={()=>{setshow(true)}} className='btn_form click_but'>{t("add_money")}</button>    
            }
                
            </div>
            </Col>:<></>)
          })}
          </Row>
          <Modal okText={t('pay')} cancelText={t('close')} title={t("add_money")} open={show} onOk={handleOk} onCancel={handleCancel}>
  <input className='input_summa' type='text' placeholder={t("summa")} value={summa} onChange={onchange} />       
</Modal>

    </div>
  )
}
