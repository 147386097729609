
import { useTranslation } from 'react-i18next'
import logo from '../new_images/logo.png'
import { Link } from 'react-router-dom'
import { IoLogoFacebook, IoLogoInstagram, IoLogoLinkedin, IoLogoYoutube } from 'react-icons/io5'


export default function Footer() {
  const {t} =useTranslation()
 
  return (
    <div className='footer'>
       <div className='navbar_brand'>
        <img src={logo} />
        </div>    
        <ul>
            <li className='link_li'><Link className='navbar_link' to={'/'}>{t("main")}</Link></li>
            <li className='link_li'><Link className='navbar_link' to={'/support'}>{t("support")}</Link></li>
            <li className='link_li'><Link className='navbar_link' to={'/'}>{t("aboutus")}</Link></li>
          </ul>
          <div className='footer_btns_box'>
            <a href="#" target='_blank' className='footer_btn'><IoLogoFacebook /></a>
            <a href="#" target='_blank' className='footer_btn'><IoLogoInstagram /></a>
            <a href="#" target='_blank' className='footer_btn'><IoLogoLinkedin /></a>
            <a href="#" target='_blank' className='footer_btn'><IoLogoYoutube /></a>
          </div>
    </div>
  )
}
