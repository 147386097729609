import React, { useEffect, useState } from 'react'
import { useStore } from '../store/Store'
import { checkDate, formatDateList, noAvatar } from '../components/Checkers'
import { useTranslation } from 'react-i18next'
import { GoPlus } from "react-icons/go";
import man_icon from '../new_images/man_icon.png'
import woman_icon from '../new_images/woman_icon.png'
import { getLikeUsers, getUserById } from '../host/requests/User';
import { Link, useNavigate } from 'react-router-dom';
import { PuffLoader } from 'react-spinners';
import place_icon from '../new_images/place_icon_black.svg'
import place_icon_white from '../new_images/place_icon.svg'
export default function Likes() {
  const likes=useStore(state=>state.likes)
  const token_access=useStore(state=>state.token_access)
  const like_next=useStore(state=>state.like_next)
  const isBlue=useStore(state=>state.isBlue)
  const addusersrandom=useStore(state=>state.addusersrandom)
  const setlike_page=useStore(state=>state.setlike_page)
  const setlikes=useStore(state=>state.setlikes)
  const setlike_next=useStore(state=>state.setlike_next)
  const setlike_count=useStore(state=>state.setlike_count)
  const like_page=useStore(state=>state.like_page)
  const [like_spin, setlike_spin]=useState(false)
  const navigate=useNavigate()
  const {t} =useTranslation()
 
  useEffect(()=>{
    setlike_count(0)
  }, [])
  
  const getLikes=async()=>{
    if(like_next!==null){
      setlike_spin(true)
      try{
        var res=await getLikeUsers({page:like_page}, token_access)
      
        setlike_next(res.data.next)
        setlike_page(like_page+1)
        setlikes(res.data.results)
        setlike_spin(false)
     }catch(error){
        console.log(error)
       
     }
    }
  }
 
  return (
    <div className='like_big_box'>
        <h1 className='title'>{t("who_liked_me")}</h1>
      {likes.length==0? <div className='listBox'>
        <div className='emptyList'></div>
    </div>:<div className='like_box'>
        {likes.map((item, key)=>{
            
      return(item.user!=null?<div className='like_item_col'><Link to={"/cabinet/user/"+item.user.id} key={key} className='like_item'>
        <span>{checkDate(item.created_at, t("error_api"))}</span>
      <div className='user_box'>
       <div className='avatar'>
       <div className='avatar_box' style={{backgroundImage:`url(${item.user!==null && item.user.main_image!==null?item.user.main_image.media:item.user.gender=='male'?man_icon:woman_icon})`}}></div>
         
       </div>
       <div className='username'>
            <h1>{item.user!==null?item.user.first_name:""}</h1>
            <div className='like_address'><p>{formatDateList(item.created_at, t("error_api"))}</p><img src={isBlue?place_icon_white:place_icon} /></div>
       </div>
     </div>
     
     </Link></div>:<></> )
    })}
            
      {like_next!==null && like_next!=="a"?
       <div onClick={()=>{getLikes()}} className='addBtnBox'>
       {like_spin?<div className='loadBtn_box'><PuffLoader color='#FF6865' /></div>: <button><GoPlus/></button>}
      </div>
      :<></>}
   </div>}
        </div>
  )
}
