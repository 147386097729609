import { message } from "antd"

export const successAlert=(text)=>{
    message.success(text, 3)
}
export const dangerAlert=(text)=>{
    message.error(text, 3)
}

export const formatPayment=(number)=>{
    if(number!=null){
        var formattedNumber = number.toLocaleString('ru-RU').replace(/,/g, ' ')
    }else{
        var formattedNumber="0"
    }
   
   return(formattedNumber)
}