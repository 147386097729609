import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Login from './Login'
import Registration from './Registration'
import ChangePassword from './ChangePassword'
import Dashboard from './Dashboard'
import Support from './Support'

export default function LoginBox() {
 
  return (
    <div>
      
        <Routes>
        <Route path='' element={<Dashboard/>} />
        <Route path='/support' element={<Support/>} />
       
        <Route path='login' element={
          <>
       
        <Login/>
        </>
        } />
        <Route path='register' element={
           <>
          
       <Registration/>
       </>
        } />
        <Route path='change-password' element={
        <>
      
       <ChangePassword/>
       </>
       } />
        </Routes>
    </div>
  )
}
