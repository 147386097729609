
import offerta_uz from '../files/offerta_uz.pdf'
import offerta_en from '../files/offerta_en.pdf'
import offerta_ru from '../files/offerta_ru.pdf'

export const offerta={
    'uz':offerta_uz,
    'ru':offerta_ru,
    'en':offerta_en,
}
