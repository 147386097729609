import { httpRequestGet } from "../Host"

export const getCountriesHttp=async(data, token)=>{
    var res=await httpRequestGet('/countries/', null, token)
    return(res)
}
export const getHobbiesHttp=async(data, token)=>{
    var res=await httpRequestGet('/hobbies/', null, token)
    return(res)
}

export const getStatesHttp=async(data, token)=>{
    var res=await httpRequestGet('/states/', data, token)
    return(res)
}