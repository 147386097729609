import React from 'react'
import '../css/Loader.css'
import { ClipLoader, GridLoader, PuffLoader} from 'react-spinners'
export default function Loader() {
  return (
    <div className='loader_box'> 
     
<PuffLoader  size={200} color="#FF6865" />
    </div>
  )
}
