import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { useStore } from '../store/Store'
import click from '../new_images/click.png'
import { Modal, Tabs } from 'antd'
import { dangerAlert, formatPayment } from '../components/Alert'
import { createClick, getBalance } from '../host/requests/User'
import Tushumlar from './payments/Tushumlar'
import { FaArrowDown, FaArrowUp } from 'react-icons/fa'
import Xarajatlar from './payments/Xarajatlar'
export default function Payments() {
    const {user, token_access, balance, setloader, setbalance}=useStore()
    const [show, setshow]=useState(false)
    const [summa, setsumma]=useState("")
    useEffect(()=>{
      getUserData()
    }, [token_access])
    const getUserData=async()=>{
      setloader(true)
      try{
        var res3=await getBalance(token_access)
        setbalance(res3.data.balance)
        setloader(false)
        }catch (error){
          setloader(false)
        
       }
    }
    const handleOk=()=>{
     
      var sum_str=Number(summa.replaceAll(" ", ""))
         if(isNaN(sum_str) || sum_str<=0){
          dangerAlert(t('write_summa'))
         }else{
          setloader(true)
          createClick({"amount":String(sum_str)}, token_access).then(res=>{
            setloader(false)
            if(res!=null && res.data!=null){
               window.location.href=`https://my.click.uz/services/pay?service_id=${res.data.service_id}&merchant_id=${res.data.merchant_id}&amount=${res.data.amount}&transaction_param=${res.data.transaction_param}&return_url=${window.location.href}`
            }else{
              dangerAlert(t("server_error_text"))
            }
               
          }).catch(err=>{
            dangerAlert(t("server_error_text"))
          })
         }
    }
    const handleCancel=()=>{
         setshow(false)
    }

    const onchange=(e)=>{
      var val=e.target.value
      if(val.length!==0){
        val=Number(val.replace(/\D/g, '')).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
      setsumma(val)
    }
  return (
    <div className='payments'>
       <div className='payments_header'>
        <p>{t("your_summ")}: <b>{formatPayment(balance)}</b> {t("so'm")}</p>
        <p>{t("add_money")}: <div onClick={()=>{setshow(true)}} className='click_btn' ><img src={click} /></div></p>
       </div>
       <div className='payments_header mobile_head'>
        <p><b>{formatPayment(balance)}</b> {t("so'm")}</p>
        <p><div onClick={()=>{setshow(true)}} className='click_btn' ><img src={click} /></div></p>
       </div>
       {/* <div className='payments_data'>
       <Tabs
    defaultActiveKey="1"
    items={[ {
        key: 1,
        label: t("tushumlar"),
        children: <Tushumlar/>,
        icon: <FaArrowDown color='#1bdf14' />,
      },
      {
        key: 2,
        label: t("xarajatlar"),
        children: <Xarajatlar/>,
        icon: <FaArrowUp color='#ff2a2a' />,
      }
    ]
    }
  />
       </div> */}

<Modal okText={t('pay')} cancelText={t('close')} title={t("add_money")} open={show} onOk={handleOk} onCancel={handleCancel}>
  <input className='input_summa' type='text' placeholder={t("summa")} value={summa} onChange={onchange} />       
</Modal>
    </div>
  )
}
