import { Button, Form, Input } from "antd"
import axios from "axios"
import { useTranslation } from "react-i18next"
import { api } from "../host/Host"
import { dangerAlert, successAlert } from "../components/Alert"
import { useStore } from "../store/Store"

export default function Support(){
    const {t}=useTranslation()
    const setloader=useStore(state=>state.setloader)
    const [form] = Form.useForm();
    const onFinish=(val)=>{
      setloader(true)
          axios.post(`${api}/contacts/create/`, val).then(res=>{
            successAlert(t("data_sended"))
            setloader(false)
            form.setFieldsValue({
              name:'',
              text:'',
              contact:'',
            })
          }).catch(err=>{
            setloader(false)
            dangerAlert(t('server_error_text'))
          })
    }
    return(<div className="support">
            <h1 className="title">{t("has_ques")}</h1>
            <p className="title_text">{t("support_text")}</p>
            <div className="support_form">
            <Form
            form={form}
    name="basic"
    labelCol={{
      span: 24,
    }}
    wrapperCol={{
      span: 24,
    }}
   initialValues={{
      remember: true,
    }}
    onFinish={onFinish}
    // onFinishFailed={onFinishFailed}
    
  >
     <Form.Item
      name="name"
      rules={[
        {
           required: true,
           message: t("not_empty"),
            
        }
      ]}
    >
      <Input  className='login_input' placeholder={t("firstname")} />
    </Form.Item>
    <Form.Item
      name="contact"
      rules={[
        {
           required: true,
           message: t("not_empty"),
            
        }
      ]}
    >
      <Input  className='login_input' placeholder={t("emailorphone")} />
    </Form.Item>
    <Form.Item
      name="text"
      rules={[
       {
           required: true,
           message: t("not_empty"),
            
        }
      ]}
    >
      <Input.TextArea rows={10} className='login_input' placeholder={t("support_ques")} />
    </Form.Item>
   
<Form.Item
      wrapperCol={{
        span: 24,
      }}
    >
      <Button className='btn_form' htmlType="submit">
        {t("send")}
      </Button>
    </Form.Item>
  </Form>
            </div>
    </div>)
}